<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDACareers',
  metaInfo: { title: 'Careers' },
  extends: View,

  mixins: [
    LoadSections([
      'KDACareersHeader',
      'KDACareersSecond',
      'KDACareersLifeKeda',
      'KDACareersLooks',
      'KDACareersList',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'careers'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Careers'
    })
  },
};
</script>
